import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa'

import Seo from '../components/seo'
import { responsiveTitle1 } from '../components/typography.module.css'
import Layout from '../containers/layout'
import styles from './../styles/404.module.css'

// https://lottiefiles.com/6734-404-error-glitch-2
import lottie404Animation from './../lib/lottie-404-animation.json'

const NotFoundPage = () => {
  const [lottieLoaded, setLottieLoaded] = useState(false)
  useEffect(() => {
    require('@lottiefiles/lottie-player')
    setLottieLoaded(true)
  })

  return (
    <Layout>
      <Seo title='404: Page was not found' />
      <div className={styles.container}>
        <h1 className={responsiveTitle1}>Page not found!</h1>
        <div className={styles.animation}>
          {lottieLoaded &&
            <lottie-player
              autoPlay
              loop
              playsInline
              mode='normal'
              src={JSON.stringify(lottie404Animation)}
              style={{ width: 300, height: 300, margin: '2rem auto' }}
            />}
        </div>

        <Link to='/' className={styles.homeLink}>
          <FaArrowLeft />
          Go back home
        </Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage
